import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./fonts/stylesheet.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/store";

import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_ID!);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
