import React, { useEffect } from "react";
import Header from "./components/header/Header";
import { fetchCountries } from "./redux/slices/countries";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import Main from "./screens/main/Main";

function App() {
  const dispatch = useAppDispatch();
  const { loading, error } = useAppSelector((state) => state.countries);

  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (/android/i.test(userAgent)) {
      document.body.setAttribute("data-device", "android");
    } else if (/windows/i.test(userAgent)) {
      document.body.setAttribute("data-device", "windows");
    } else if (/iphone|ipod|ipad|macintosh/i.test(userAgent)) {
      document.body.setAttribute("data-device", "apple");
    }
  }, []);

  if (error) return <div>{error}</div>;

  return (
    <div className="container">
      <Header />
      {loading ? <div>loading</div> : <Main />}
    </div>
  );
}

export default App;
