import React, { useMemo, useCallback } from "react";
import styles from "../styles/main.module.css";
import { useAppSelector } from "../../../redux/hooks";
import { Country } from "../../../redux/types";

const AfricaRegion = () => {
  const { countries } = useAppSelector((state) => state.countries);

  const filterAfricaCountries = useCallback((countries: Country[]) => {
    return countries.filter((country) => country.region === "Africa");
  }, []);

  const sortedAfricaCountries = useMemo(() => {
    const africaCountries = filterAfricaCountries(countries);
    return africaCountries.sort((a, b) => a.name.localeCompare(b.name));
  }, [countries, filterAfricaCountries]);

  return (
    <div className={styles.region_container}>
      <table>
        <thead>
          <tr>
            <th>Africa</th>
            <th>Value</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {sortedAfricaCountries.map((country) => (
            <tr key={country.name}>
              <td>
                <img
                  src={`${process.env.REACT_APP_DOMAIN}${country.flag}`}
                  alt="flag"
                />
                {country.name}
              </td>
              <td>{country.value}</td>
              {country.status === "Active" ? (
                <td style={{ color: "#5CBE6B" }}>{country.status}</td>
              ) : country.status === "No info" ? (
                <td style={{ color: "#75B4EF" }}>{country.status}</td>
              ) : (
                <td style={{ color: "#E34F4F" }}>{country.status}</td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <p className={styles.region_warning}>
          *This is not a complete list of Africa Steam accounts that we can
          refill.
        </p>
        <a href="http://t.me/playwalletbot" className={styles.region_link}>
          All Africa Region &#8594;{" "}
        </a>
      </div>
    </div>
  );
};

export default AfricaRegion;
